<template>
  <div class="w-full">
    <vs-table
      maxHeight="72vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="fornecedores"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">
              Fornecedores de produtos e serviços para a EMOPS
            </h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroFornecedor @update="getFornecedores" />
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome_fantasia">Nome fantasia</vs-th>
        <vs-th sort-key="razao_social">Razão social</vs-th>
        <vs-th sort-key="cpf_cnpj">CPF/CNPJ</vs-th>
        <vs-th sort-key="email">Email</vs-th>
        <vs-th sort-key="cpo">PCO</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome_fantasia">
            {{ data[indextr].nome_fantasia }}
          </vs-td>
          <vs-td :data="data[indextr].razao_social">
            {{ data[indextr].razao_social }}
          </vs-td>
          <vs-td :data="data[indextr].cpf_cnpj">
            {{ data[indextr].cpf_cnpj }}
          </vs-td>
          <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
          <vs-td :data="data[indextr].pco">
            <vs-chip :color="data[indextr].pco ? 'success' : 'dark'">{{
              data[indextr].pco ? "Sim" : "Não"
            }}</vs-chip>
          </vs-td>
          <vs-td class="actions">
            <ModalEditarFornecedor
              :fornecedor="data[indextr]"
              @update="getFornecedores"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="fornecedores.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum fornecedor cadastrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroFornecedor from "./cadastro.vue"
import ModalEditarFornecedor from "./editar.vue"

export default {
  data () {
    return {
      fornecedores: [
      ]
    }
  },
  methods: {
    async getFornecedores () {
      this.$vs.loading()
      try {
        this.fornecedores = await this.$http.get(`fornecedor`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  components: {
    ModalCadastroFornecedor,
    ModalEditarFornecedor
  },
  mounted () {
    this.getFornecedores()
  }
};
</script>

<style>
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
</style>
