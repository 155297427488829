<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalCadastroFornecedor = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar fornecedor"
      @hide="modalCadastroFornecedor = false"
      :active="modalCadastroFornecedor"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <div class="pr-4">
          <!-- DADOS PESSOAIS -->
          <ChipCenter title="Dados pessoais" color="primary"></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="6" class="pr-1">
              <!-- TIPO PESSOA-->
              <div style="display: inline-block; margin-right: 20px">
                <label style="color: black; font-size: 13px">Tipo Pessoa</label>
                <br />
                <el-select
                  filterable
                  clearable
                  v-validate="'required'"
                  class="w-full"
                  name="tipo"
                  v-model="fornecedor.tipo"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="pessoa in tipo_pessoa"
                    class="select-info"
                    :value="pessoa.value"
                    :label="pessoa.nome"
                    :key="pessoa.value"
                  ></el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('tipo')"
                    >{{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>

            <vs-col vs-w="6" class="pl-1">
              <!-- CPF CNPJ-->
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full mt-8"
                  name="cpf_cnpj"
                  v-validate="
                    fornecedor.tipo == 0
                      ? 'required|min:11|max:11'
                      : 'required|min:14|max:14'
                  "
                  :label-placeholder="fornecedor.tipo == 0 ? 'CPF' : 'CNPJ'"
                  v-model="fornecedor.cpf_cnpj"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('cpf_cnpj')"
                  >{{ $validators.empty }}</span
                >
              </div>
            </vs-col>

            <vs-col vs-w="6" class="pl-1">
              <!-- NOME FANTASIA -->
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full"
                  name="nome_fantasia"
                  v-validate="fornecedor.tipo != 0 ? 'required' : ''"
                  label-placeholder="Nome fantasia"
                  v-model="fornecedor.nome_fantasia"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('nome_fantasia')"
                  >{{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="6" class="pr-1" v-if="fornecedor.tipo != 0">
              <!-- RAZAO SOCIAL -->
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full"
                  name="razao_social"
                  v-validate="fornecedor.tipo != 0 ? 'required' : ''"
                  label-placeholder="Razao Social"
                  v-model="fornecedor.razao_social"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('razao_social')"
                  >{{ $validators.empty }}</span
                >
              </div>
            </vs-col>

            <vs-col vs-w="6" class="pr-1" v-if="fornecedor.tipo != 0">
              <!-- INSC. ESTADUAL -->
              <vs-input
                class="inputx w-full"
                name="inscricao_estadual"
                v-validate="'numeric'"
                label-placeholder="Insc. Estadual"
                v-model="fornecedor.inscricao_estadual"
              />
            </vs-col>
            <vs-col vs-w="6" class="pl-1" v-if="fornecedor.tipo != 0">
              <!-- INSC. MUNICIPAL -->
              <vs-input
                class="inputx w-full"
                name="inscricao_municipal"
                v-validate="'numeric'"
                label-placeholder="Insc. Municipal"
                v-model="fornecedor.inscricao_municipal"
              />
            </vs-col>
          </vs-row>

          <!-- CONTATOS -->
          <ChipCenter
            class="mt-4"
            title="Informações de contato"
            color="primary"
          ></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <!-- EMAIL -->
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full"
                  name="email"
                  v-validate="'email|required'"
                  label-placeholder="Email"
                  v-model="fornecedor.email"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('email')"
                  >{{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="12">
              <!-- PESSOA CONTATO -->
              <vs-input
                class="inputx w-full"
                name="contatoNome"
                label-placeholder="Pessoa de contato"
                v-model="fornecedor.contato"
              />
            </vs-col>
            <vs-col vs-w="6" class="pr-1">
              <!-- TEL RESIDENCIAL -->
              <vs-input
                class="inputx w-full"
                name="telefoneResidencial"
                label-placeholder="Telefone Residencial"
                type="tel"
                v-mask="'(##)#########'"
                v-model="fornecedor.telefone"
              />
            </vs-col>
            <vs-col vs-w="6" class="pl-1">
              <!-- TEL COMERCIAL -->
              <vs-input
                class="inputx w-full"
                name="telefoneComercial"
                type="tel"
                v-mask="'(##)#########'"
                label-placeholder="Telefone Comercial"
                v-model="fornecedor.telefone_2"
              />
            </vs-col>
          </vs-row>

          <!-- OUTROS -->
          <ChipCenter title="Outros" color="primary"></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <!-- OBS -->
              <vs-input
                class="inputx w-full"
                name="obs"
                label-placeholder="Obs"
                v-model="fornecedor.obs"
              />
            </vs-col>
          </vs-row>
          <vs-checkbox
            class="mt-4 ml-5"
            icon-pack="feather"
            icon="icon-check"
            v-model="fornecedor.pco"
            >Fornece para o PCO</vs-checkbox
          >
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
export default {
  name: "ModalCadastroFornecedor",
  data () {
    return {
      fornecedor: { tipo: 0 },
      tipo_pessoa: [
        { value: 0, nome: "Fisica" },
        { value: 1, nome: "Juridica" },
      ],
      modalCadastroFornecedor: false,
      error: false,
    }
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.cadastrarFornecedor()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            })
          }
        })
      })
    },
    async cadastrarFornecedor () {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$vs.loading()
          try {
            const res = await this.$http.post("fornecedor", this.fornecedor)
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: "cadastrar",
              setor: "comercial",
              ip: window.localStorage.getItem("ip"),
              texto: "Cadastro do fornecedor N°" + res.id,
            }
            await this.$logger(logData)
            this.fornecedor = {}
            this.$vs.notify(this.$notify.Success)
            this.modalCadastroFornecedor = false
          } catch (err) {
            const error = this.$httpErrors(err)
            this.$vs.notify(error)
          } finally {
            await this.$emit("update")
            this.$vs.loading.close()
          }
        }
      })
    },
  },
  components: components,
};
</script>

<style></style>
