<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalEditarFornecedor = true"
    ></EditButton>

    <SideBar
      title="Editar fornecedor"
      @hide="cancelar"
      :active="modalEditarFornecedor"
      textSucces="Atualizar"
      icon="edit"
      :disabled="!$atividades.permissoes.includes(15)"
      @excluir="excluirFornecedor()"
      @status="mudarStatusFornecedor()"
      @success="submitForm()"
      size="small"
      :item="fornecedor"
    >
      <div slot="body">
        <div class="pr-4">
          <!-- DADOS PESSOAIS -->
          <ChipCenter title="Dados pessoais" color="primary"></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="6" class="pl-1">
              <!-- CPF CNPJ-->
              <vs-input
                class="inputx w-full"
                name="cpf_cnpj"
                v-validate="
                  fornecedor.tipo == 0
                    ? 'required|min:11|max:11'
                    : 'required|min:14|max:14'
                "
                :label-placeholder="fornecedor.tipo == 0 ? 'CPF' : 'CNPJ'"
                v-model="fornecedor.cpf_cnpj"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('cpf_cnpj')"
                >{{
                  fornecedor.tipo == 0 ? $validators.cpf : $validators.cnpj
                }}</span
              >
            </vs-col>
            <vs-col vs-w="6" class="pl-1">
              <!-- NOME FANTASIA -->
              <vs-input
                class="inputx w-full"
                name="nome_fantasia"
                v-validate="fornecedor.tipo != 0 ? 'required' : ''"
                label-placeholder="Nome fantasia"
                v-model="fornecedor.nome_fantasia"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('nome_fantasia')"
                >{{ $validators.empty }}</span
              >
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="6" class="pr-1" v-if="fornecedor.tipo != 0">
              <!-- RAZAO SOCIAL -->
              <vs-input
                class="inputx w-full"
                name="razao_social"
                v-validate="fornecedor.tipo != 0 ? 'required' : ''"
                label-placeholder="Razao Social"
                v-model="fornecedor.razao_social"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('razao_social')"
                >{{ $validators.empty }}</span
              >
            </vs-col>

            <vs-col vs-w="6" class="pr-1" v-if="fornecedor.tipo != 0">
              <!-- INSC. ESTADUAL -->
              <vs-input
                class="inputx w-full"
                name="inscricao_estadual"
                v-validate="'numeric'"
                label-placeholder="Insc. Estadual"
                v-model="fornecedor.inscricao_estadual"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('inscricao_estadual')"
                >{{ $validators.numeric }}</span
              >
            </vs-col>
            <vs-col vs-w="6" class="pl-1" v-if="fornecedor.tipo != 0">
              <!-- INSC. MUNICIPAL -->
              <vs-input
                class="inputx w-full"
                name="inscricao_municipal"
                v-validate="'numeric'"
                label-placeholder="Insc. Municipal"
                v-model="fornecedor.inscricao_municipal"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('inscricao_municipal')"
                >{{ $validators.numeric }}</span
              >
            </vs-col>
          </vs-row>
          <!-- CONTATOS -->
          <ChipCenter
            class="mt-4"
            title="Informações de contato"
            color="primary"
          ></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <!-- EMAIL -->
              <vs-input
                class="inputx w-full"
                name="email"
                v-validate="'email|required'"
                label-placeholder="Email"
                v-model="fornecedor.email"
              />
              <span class="text-danger text-sm" v-show="errors.has('email')">{{
                $validators.email
              }}</span>
            </vs-col>
            <vs-col vs-w="12">
              <!-- PESSOA CONTATO -->
              <vs-input
                class="inputx w-full"
                name="contatoNome"
                label-placeholder="Pessoa de contato"
                v-model="fornecedor.contato"
              />
            </vs-col>
            <vs-col vs-w="6" class="pr-1">
              <!-- TEL RESIDENCIAL -->
              <vs-input
                class="inputx w-full"
                name="telefoneResidencial"
                v-validate="'numeric'"
                type="tel"
                v-mask="'(##)#########'"
                label-placeholder="Telefone Residencial"
                v-model="fornecedor.telefone"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('telefoneResidencial')"
                >{{ $validators.phone }}</span
              >
            </vs-col>
            <vs-col vs-w="6" class="pl-1">
              <!-- TEL COMERCIAL -->
              <vs-input
                class="inputx w-full"
                name="telefoneComercial"
                v-validate="'numeric'"
                type="tel"
                v-mask="'(##)#########'"
                label-placeholder="Telefone Comercial"
                v-model="fornecedor.telefone_2"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('telefoneComercial')"
                >{{ $validators.phone }}</span
              >
            </vs-col>
          </vs-row>

          <!-- OUTROS -->
          <ChipCenter title="Outros" color="primary"></ChipCenter>
          <vs-row vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <!-- OBS -->
              <vs-input
                class="inputx w-full"
                name="obs"
                label-placeholder="Obs"
                v-model="fornecedor.obs"
              />
            </vs-col>
          </vs-row>
          <vs-checkbox
            class="mt-4 ml-5"
            icon-pack="feather"
            icon="icon-check"
            v-model="fornecedor.pco"
            >Fornece para o PCO</vs-checkbox
          >
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
export default {
  name: "modalEditarFornecedor",
  props: { fornecedor: Object },
  data() {
    return {
      modalEditarFornecedor: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.fornecedor);
    this.originalData = this.fornecedor;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarFornecedor();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarFornecedor() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(
          `fornecedor/${this.fornecedor.id}`,
          this.fornecedor
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição do fornecedor N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async excluirFornecedor() {
      this.$vs.loading();
      try {
        await this.$http.delete(`fornecedor/${this.fornecedor.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Atualização do fornecedor N°" + this.fornecedor.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusFornecedor() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`fornecedor/${this.fornecedor.id}`, {
          lixeira: !this.fornecedor.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status do fornecedor N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.fornecedor, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarFornecedor = false;
    }
  },
  components: components
};
</script>

<style></style>
